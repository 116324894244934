import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import ContentSection from './components/ContentSection';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <HeroSection />
      <ContentSection title="In-depth Features">
        {/* Content for In-depth Features */}
      </ContentSection>
      <ContentSection title="Short Documentaries">
        {/* Content for Short Documentaries */}
      </ContentSection>
      <ContentSection title="Inspiring Interviews">
        {/* Content for Inspiring Interviews */}
      </ContentSection>
      <ContentSection title="Photo Essays">
        {/* Content for Photo Essays */}
      </ContentSection>
      <ContentSection title="Community Stories">
        {/* Content for Community Stories */}
      </ContentSection>
      <Footer />
    </div>
  );
}

export default App;
