import React from 'react';
import './styles/ContentSection.css'; // Make sure to create this CSS file for styling

const ContentSection = ({ title, children, id }) => {
  return (
    <section id={id} className="content-section">
      <div className="container">
        <h2 className="section-title">{title}</h2>
        <div className="section-content">
          {children}
        </div>
      </div>
    </section>
  );
}

export default ContentSection;
