import React from 'react';
import { Link } from 'react-router-dom';
import './styles/Header.css'; // Assuming you have a CSS file for styles

const Header = () => {
  return (
    <header className="header">
      <div className="logo-container">
        <Link to="/">
          <img src="./gritLogo.png" alt="The Grit Network Logo" className="logo" />
        </Link>
      </div>
      <nav className="navigation">
        <ul className="nav-links">
          <li><Link to="/features">In-depth Features</Link></li>
          <li><Link to="/documentaries">Short Documentaries</Link></li>
          <li><Link to="/interviews">Inspiring Interviews</Link></li>
          <li><Link to="/essays">Photo Essays</Link></li>
          <li><Link to="/community">Community</Link></li>
        </ul>
      </nav>
      <div className="auth-container">
        {/* Implement authentication logic here, e.g., sign in/sign out links */}
      </div>
    </header>
  );
};

export default Header;
