import React from 'react';
import './styles/HeroSection.css'; // Ensure this CSS file exists

const HeroSection = () => {
  return (
    <div className="hero-section">
      <video autoPlay loop muted playsInline className="hero-video">
        <source src="/assets/gritnetwVideo.mp4" type="video/mp4" />
      </video>
      <div className="hero-content">
        <h1>The Grit Network</h1>
        <p>Discover Stories of Perseverance and Triumph</p>
        <button className="explore-button">Explore Now</button>
      </div>
    </div>
  );
};

export default HeroSection;
